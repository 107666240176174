<template>
    <div class="steps registration-steps">
        <div class="login-card" :style="'padding-top:' + (register.step !== 'login' ? insets.top + 'px!important' : 0)">
            <div class="header background-gray2" v-if="register.step !== 'complete' && register.step !== 'login'" style="position: fixed!important;">
                <i class="cf-back absolute p-3 justify-self-start" style="left: 0" @click.prevent="previousStep()"></i>
                <div @click.prevent="openCrashReport" class="app-only m-2 absolute chat-icon d-md-none background-gray2 rounded-circle d-flex align-items-center justify-content-center header-icon-container" style="right: 0">
                    <img alt="info-chat" src="~/assets/images/icons/info-chat.svg" width="18px">
                </div>
            </div>
        </div>

        <login v-if="register.step === 'login'"></login>
        <intro v-if="register.step === 'intro'"></intro>
        <user-name v-if="register.step === 'name'"></user-name>
        <user-type v-if="register.step === 'type'"></user-type>

        <account-type v-if="register.step === 'account-type'"></account-type>
        <search-accounts v-if="register.step === 'search-accounts'"></search-accounts>
        <!--<account-address v-if="register.step === 'account-address'"></account-address>-->
        <account-vat-id v-if="register.step === 'account-vat-id'"></account-vat-id>

        <birth-date v-if="register.step === 'birth-date'"></birth-date>
        <gender v-if="register.step === 'gender'"></gender>
        <email v-if="register.step === 'e-mail'"></email>
        <password v-if="register.step === 'password'"></password>
        <complete v-if="register.step === 'complete'"></complete>

        <div class="bottom-bar position-fixed" v-if="!keyboardOpened && register.step !== 'complete' && register.step !== 'login'" :style="'bottom:' + (insets ? insets.bottom + 'px' : 0)">
            <a href="#" @click.prevent="setStep('login')" class="text-primary">
                {{ $t('Hai già un account?') }}
            </a>
        </div>
    </div>
</template>

<script>

    import { mapActions, mapState } from 'vuex'

    import UserName from '~/components/Register/Mobile/Steps/Name'
    import UserType from '~/components/Register/Mobile/Steps/Type'
    import AccountAddress from "~/components/Register/Mobile/Steps/AccountAddress";
    import AccountType from "~/components/Register/Mobile/Steps/AccountType";
    import SearchAccounts from "~/components/Register/Mobile/Steps/SearchAccounts";
    import AccountVatId from "~/components/Register/Mobile/Steps/AccountVatId";
    import Login from '~/components/Register/Mobile/Steps/Login'
    import Intro from '~/components/Register/Mobile/Steps/Intro'
    import BirthDate from "~/components/Register/Mobile/Steps/BirthDate";
    import Gender from "~/components/Register/Mobile/Steps/Gender";
    import Email from "~/components/Register/Mobile/Steps/Email";
    import Password from "~/components/Register/Mobile/Steps/Password";
    import Complete from "~/components/Register/Mobile/Steps/Complete";

    export default {

        components: {
            Complete,
            Password,
            Email,
            Gender,
            BirthDate,
            UserName,
            UserType,
            Intro,
            Login,
            AccountAddress,
            AccountType,
            SearchAccounts,
            AccountVatId
        },

        computed: {
            ...mapState([
                'register',
                'insets',
                'keyboardOpened'
            ])
        },

        methods: {
            ...mapActions({
                'setStep': 'register/setStep',
                'openCrashReport': 'openCrashReport'
            }),
            previousStep() {
                this.$nuxt.$emit('login-previous-step');
            }
        }
    }
</script>