<template>
    <div class="px-3">
        <strong class="title text-brand" v-if="register.data.account.type === 'beauty-centre'">{{ $t('Qual è la partita iva del tuo centro estetico?')}}</strong>
        <strong class="title text-brand" v-if="register.data.account.type === 'school'">{{ $t('Qual è la partita iva della tua scuola?')}}</strong>
        <strong class="title text-brand" v-if="register.data.account.type === 'company'">{{ $t('Qual è la partita iva del tua azienda?')}}</strong>
        <div class="description mt-3">
            {{ $t('Inserisci la partita iva del tuo centro estetico, per ottenere la verifica della tua pagina aziendale.')}}
        </div>
        <div class="input-group mt-4">
            <div class="input-group-prepend input-group-prepend-sm">
                <span class="input-group-text font-weight-bold background-white border-0">IT</span>
            </div>
            <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="11" class="form-control form-control-sm" v-model="vat_id">
        </div>
        <button class="btn btn-brand w-100 mt-3" @click.prevent="nextStep()" :disabled="!vat_id">
            {{ $t('Verifica') }}
        </button>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        computed: {
            ...mapState([
                'register',
            ]),
            ...mapFields('register', [
                'data.account.vat_id'
            ])
        },

        methods: {

            ...mapMutations({
                'setStep': 'register/SET_STEP',
                'setAccountType': 'user/SET_ACCOUNT_TYPE'
            }),
            nextStep() {

                this.$nuxt.$loading.start();

                this.$axios.$post('/api/validate-vat-id', { 'vat_id': this.vat_id }).then(() => {
                    this.setStep('search-accounts')
                }).catch(() => {

                })
                .finally(() => {
                    this.$nuxt.$loading.finish();
                })
            }
        },

        created () {
            this.$nuxt.$on('login-previous-step', () => {
                this.setStep('account-type')
            })
        },
    }
</script>

<style lang="scss" scoped>
    .profile-type-box {
        width: 150px;
    }
</style>