<template>
    <div class="text-center d-flex flex-column h-100">
        <slot name="card-header">
            <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="setStep('search-accounts')"></i>
                <span v-if="type === 'school'">{{ $t('Aggiungi una scuola di estetica') }}</span>
                <span v-else>{{ $t('Collegati ad un luogo di lavoro') }}</span>
            </div>
        </slot>
        <div class="card-body p-3">
            <div class="mt-lg-5 mb-2">
                <img alt="step-07" class="w-100 icon" src="~/assets/images/steps/step-07.png">
            </div>
            <strong class="title p-3">
                <span v-if="account.id && workExperience.workExperience.type === 'owner'">{{ $t('Dove si trova il tuo centro estetico') }}</span>
                <span v-else>
                    <span v-if="type !== 'school'">{{ $t('Aggiungi l\'indirizzo del centro estetico:')}}</span>
                    <span v-else>{{ $t('Aggiungi l\'indirizzo della scuola:')}}</span>
                    <br>
                    «{{ account.name }}»
                </span>
            </strong>
            <div class="description p-4"> {{ $t('Per individuare meglio la struttura è importante inserire l\'indirizzo corretto')}}</div>

            <div @click.prevent="$refs.accountAddress.openModal()" class="font-size-extra mt-3 border-bottom d-lg-none d-block pb-2">
                <span v-if="account.address">{{ account.address }}</span>
                <span class="text-light-grey font-weight-light font-size-2x" v-else>{{ $t('Clicca per aggiungerlo') }}</span>
            </div>
            <account-address-modal :account="account" v-bind.sync="account" ref="accountAddress" :type="type"></account-address-modal>

            <!--<div class="d-lg-block d-none">-->
                <!--<vue-google-autocomplete id="map"-->
                                         <!--classname="form-control form-control-sm mb-2"-->
                                         <!--v-on:placechanged="getAddressData"-->
                                         <!--:enable-geolocation="true"-->
                                         <!--:placeholder="$t('Inserisci un indirizzo')"-->
                                         <!--:country="['it']"-->
                                         <!--:value="address"-->
                                         <!--:disabled="account.id"-->
                <!--&gt;-->
                <!--</vue-google-autocomplete>-->
                <!--<v-select index="id"-->
                          <!--label="name"-->
                          <!--class="select-sm mb-2"-->
                          <!--:options="cities"-->
                          <!--@search="onSearchCities"-->
                          <!--:filterable="false"-->
                          <!--:placeholder="$t('Cerca la tua città')"-->
                          <!--v-model="city_id"-->
                          <!--:disabled="account.id"-->
                <!--&gt;-->
                    <!--<template slot="no-options">-->
                        <!--{{ $t('Inserisci almeno due caratteri')}}-->
                    <!--</template>-->
                    <!--<template slot="option" slot-scope="option">-->
                        <!--<div class="d-center">-->
                            <!--{{ option.name }}-->
                        <!--</div>-->
                    <!--</template>-->
                    <!--<template slot="selected-option" slot-scope="option" v-if="account.city && (city_id === account.city.id)">-->
                        <!--<div class="d-center">-->
                            <!--{{ account.city.name }}-->
                        <!--</div>-->
                    <!--</template>-->
                <!--</v-select>-->

                <!--<v-select index="id"-->
                          <!--label="code"-->
                          <!--class="select-sm"-->
                          <!--:options="postalCodes"-->
                          <!--:filterable="false"-->
                          <!--@search="onSearchPostalCodes"-->
                          <!--:placeholder="$t('Cerca il tuo CAP')"-->
                          <!--v-model="postal_code_id"-->
                          <!--:disabled="account.id"-->
                <!--&gt;-->
                    <!--<template slot="no-options">-->
                    <!--<span v-if="!account.city_id">-->
                        <!--{{ $t('Inserisci prima la città')}}-->
                    <!--</span>-->
                        <!--<span v-else>-->
                        <!--{{ $t('Nessuna opzione disponibile') }}-->
                    <!--</span>-->
                    <!--</template>-->
                    <!--<template slot="option" slot-scope="option">-->
                        <!--<div class="d-center">-->
                            <!--{{ option.code }}-->
                        <!--</div>-->
                    <!--</template>-->
                    <!--<template slot="selected-option" slot-scope="option" v-if="account.postal_code && (postal_code_id == account.postal_code.id)">-->
                        <!--<div class="selected d-center">-->
                            <!--{{ account.postal_code.code }}-->
                        <!--</div>-->
                    <!--</template>-->
                <!--</v-select>-->
            <!--</div>-->
        </div>
        <div class="card-footer" v-if="account.address">
            <button class="btn btn-brand w-100" @click.prevent="nextStep()" :disabled="!account.address">
                <span >{{ $t('Avanti') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import AccountAddressModal from '~/components/WorkingPlace/Steps/AccountAddressModal'
    import _ from 'lodash'

    export default {
        components: {
            AccountAddressModal
        },

        data() {
            return {
                cities: [],
                postalCodes: []
            }
        },

        computed: {
            ...mapState({
                'account': 'account',
            }),
            ...mapMutations({
                'setStep': 'register/SET_STEP'
            })
        },

        methods: {
            nextStep(account) {
                this.confirmCallback(account);
                this.$nuxt.$emit('close-account-modal')
            },
            confirmData() {

                if (!this.account.id) {

                    this.$nuxt.$loading.start()

                    this.$axios.post('api/accounts', this.account)
                        .then(res => res.data)
                        .then((account) => {

                            this.$nuxt.$loading.finish()
                            this.nextStep(account)
                        }).catch((error) => {

                            this.$nuxt.$loading.finish()
                        })
                } else {
                    this.nextStep(this.account)
                }
            },
            getAddressData(addressData, placeResultData, id) {

                this.address = addressData.route + (addressData.street_number ? ', ' + addressData.street_number : '');
                this.latitude = addressData.latitude;
                this.longitude = addressData.longitude;
            },
            onSearchCities(search, loading) {
                this.searchCities(loading, search, this);
            },
            onSearchPostalCodes(search, loading) {
                this.searchPostalCodes(loading, search, this);
            },
            searchCities: _.debounce((loading, search, vm) => {

                if (search.length > 1) {
                    loading(true);
                    vm.$axios.get('/api/cities?name=' + search)
                        .then(res => {

                            vm.cities = res.data;
                            loading(false);
                        });
                }
            }, 350),
            searchPostalCodes: _.debounce((loading, search, vm) => {

                if (vm.account.city_id) {
                    loading(true);
                    vm.$axios.get('/api/postal-codes?code=' + search + '&city_id=' + vm.account.city_id)
                        .then(res => {

                            vm.postalCodes = res.data;
                            loading(false);
                        });
                }
            }, 350),
        },

        mounted () {
        },
    }
</script>