<template>
    <div class="px-3">
        <strong class="text-primary title">
            {{ $t('Che tipo di azienda hai?') }}
        </strong>
        <div class="mt-2 mb-4">{{ $t('Se non sei un\'estetista puoi creare il tuo account azienda che ti permetterà di collaborare con noi nel circuito di Confestetica') }}</div>
        <div class="d-flex align-items-center border-bottom p-3" @click.prevent="type = 'beauty-centre'">
            <img alt="beauty-centre-icon-grey" class="m-0 mr-3" src="~/assets/images/icons/beauty-centre-icon-grey.svg" width="30px">
            {{ $t('Centro estetico') }}
            <i class="fa fa-circle fa-lg text-primary ml-auto" v-if="type === 'beauty-centre'"></i>
            <i class="fa fa-circle-o fa-lg text-grey ml-auto" v-else></i>
        </div>
        <div class="d-flex align-items-center border-bottom p-3" @click.prevent="type = 'school'">
            <img alt="school-icon-grey" class="m-0 mr-3" src="~/assets/images/icons/school-icon-grey.svg" width="30px">
            {{ $t('Scuola o ente di formazione') }}
            <i class="fa fa-circle fa-lg text-primary ml-auto" v-if="type === 'school'"></i>
            <i class="fa fa-circle-o fa-lg text-grey ml-auto" v-else></i>
        </div>
        <div class="d-flex align-items-center p-3" @click.prevent="type = 'company'">
            <!--<img alt="school-icon-grey" class="m-0 mr-3" src="~/assets/images/icons/school-icon-grey.svg" width="30px">-->
            <img alt="company-icon-grey" class="m-0 mr-3" src="~/assets/images/icons/company-icon-grey.svg" width="30px">
            {{ $t('Azienda di settore') }}
            <i class="fa fa-circle fa-lg text-primary ml-auto" v-if="type === 'company'"></i>
            <i class="fa fa-circle-o fa-lg text-grey ml-auto" v-else></i>
        </div>
        <button class="btn btn-brand mt-3 w-100" @click.prevent="nextStep" :disabled="!type">
            {{ $t('Avanti') }}
        </button>
        <div class="mt-2">
            <a href="#" class="font-size-sm" @click.prevent="setStep('type')"><u>{{ $t('Sono un\'estetista') }}</u></a>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    import AccountAddressModal from '~/components/WorkingPlace/Steps/AccountAddressModal'

    export default {
        components: {
            AccountAddressModal
        },

        data() {
            return {
                type: ''
            }
        },

        methods: {

            ...mapMutations({
                'setAccountType': 'register/SET_ACCOUNT_TYPE',
                'setStep': 'register/SET_STEP',
            }),
            nextStep() {

                this.setAccountType(this.type)
                this.setStep('account-vat-id')
            }
        },

        created () {
            this.$nuxt.$on('login-previous-step', () => {
                this.setStep('type')
            })
        },
    }
</script>