<template>
    <div class="px-3">
        <strong class="title text-brand">{{ $t('Che estetista sei?')}}</strong>
        <div class="description mt-2">
            {{ $t('Crea qui il tuo account di estetista professionista o studentessa che potrai collegare alla pagina di un centro estetico in qualità di titolare o dipendente')}}
        </div>
        <div class="d-flex mt-4 mb-2 justify-content-center">
            <div class="profile-type-box d-flex justify-content-center align-items-center flex-column mr-2 text-uppercase font-size-xs" @click.prevent="nextStep('student')" :class="{active: user.profile_type === 'student'}">
                {{ $t('Estetista Studentessa') }}
            </div>
            <div class="profile-type-box d-flex justify-content-center align-items-center flex-column ml-2 text-uppercase font-size-xs" @click.prevent="nextStep('professional')" :class="{active: user.profile_type === 'professional'}">
                {{ $t('Estetista Professionista') }}
            </div>
        </div>
        <a href="#" class="font-size-sm" @click.prevent="nextStep('not_beautician')">{{ $t('Non sono un\'estetista') }}</a>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'

    export default {

        data () {
            return {
                profileType: null
            }
        },

        computed: {
            ...mapState({
                'user': ({register}) => register.data,
            })
        },

        methods: {
            ...mapActions({
                'setStep': 'register/setStep',
            }),
            ...mapMutations({
                'setProfileType': 'register/SET_PROFILE_TYPE'
            }),
            nextStep(profileType) {

                this.setProfileType(profileType)

                if (this.user.profile_type === 'not_beautician') {
                    this.setStep('account-type')
                } else {
                    this.setStep('name')
                }
            },
        },

        mounted () {
            this.$nuxt.$on('login-previous-step', () => {
                this.setStep('intro')
            })
        }
    }
</script>

<style lang="scss" scoped>
    .profile-type-box {
        width: 150px;
    }
</style>