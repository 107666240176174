<template>
    <div class="d-flex flex-column px-3">
        <div class="mt-5">
            <strong class="text-primary title text-center">
                {{ $t('Qual è il tuo indirizzo e-mail?')}}
            </strong>
        </div>
        <div class="d-flex mt-3 flex-column">
            <input class="form-control has-label" name="email" :data-vv-as="$t('E-mail')" v-validate="'required|email'" :placeholder="$t('E-mail')" autocomplete="off" v-model="email" />
            <span v-if="showErrors" class="mt-2">
                <strong class="form-error text-danger" v-if="errorList.email">{{ errorList.email[0] }}</strong>
                <strong class="form-error text-danger" v-else-if="errors.has('email')">{{ errors.first('email') }}</strong>
            </span>
        </div>
        <div class="description mt-3">
            {{ $t('Userai questo indirizzo e-mail per effettuare l\'accesso e per l\'eventuale reimpostazione della password.')}}
        </div>
        <button class="btn btn-brand mt-3 w-100" @click.prevent="validateEmail" :disabled="!email">
            {{ $t('Avanti') }}
        </button>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        data() {
            return {
                errorList: [],
                showErrors: false,
            }
        },

        watch: {
            'email'() {
                this.errorList = []
                this.showErrors = false;
            }
        },

        computed: {
            ...mapState({
                'user': ({ register }) => register.data
            }),
            ...mapFields('register', [
                'data.email'
            ]),
        },

        methods: {
            ...mapActions({
                'setStep': 'register/setStep'
            }),
            validateEmail() {

                if (this.errors.has('email')) {
                    this.showErrors = true;
                } else {
                    this.$axios.post('/api/validate-email', {email: this.email}).then(() => {

                        this.setStep('password')
                    }).catch((error) => {

                        this.errorList = error.response.data.errors;
                        this.showErrors = true;
                    })
                }
            }
        },

        created () {
            this.$nuxt.$on('login-previous-step', () => {
                if (this.user.profile_type === 'not_beautician') {
                    this.setStep('name')
                } else {
                    this.setStep('gender')
                }
            })
        },
    }
</script>