<template>
    <div class="d-flex flex-column px-3">
        <div class="mt-5">
            <strong class="text-primary title">
                {{ $t('Crea una password')}}
            </strong>
        </div>
        <div class="d-flex mt-3 flex-column">
            <input v-input-password class="form-control form-control-lg has-label" type="password" :data-vv-as="$t('Password')" v-validate="'required'" :placeholder="$t('Password')" autocomplete="off" v-model="password"/>
            <strong class="form-error text-danger mt-1" v-if="errors.has('password') && !errorList.password">{{ errors.first('password') }}</strong>
        </div>
        <strong class="form-error text-danger mt-2" v-if="errorList.password">{{ errorList.password[0] }}</strong>
        <div class="description mt-3">
            {{ $t('Inserisci una combinazione di almeno sei numeri, lettere e segni di punteggiatura (come ! e &).') }}
        </div>
        <button class="btn btn-brand mt-3 w-100" @click.prevent="validatePassword()" :disabled="!password">
            {{ $t('Avanti') }}
        </button>
    </div>
</template>

<script>

    import { mapActions } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        data() {
            return {
                'errorList': []
            }
        },

        watch: {
            'password': 'clearErrorList',
            'password_confirmation': 'clearErrorList'
        },

        computed: {
            ...mapFields('register', [
                'data.password',
                'data.password_confirmation'
            ]),
        },

        methods: {
            ...mapActions({
                'setStep': 'register/setStep'
            }),
            clearErrorList() {
                this.errorList = []
            },
            validatePassword() {

                this.$axios.post('/api/validate-password', {password: this.password, password_confirmation: this.password_confirmation}).then(() => {

                    this.setStep('complete')
                }).catch((error) => {

                    this.errorList = error.response.data.errors;
                })
            }

        },
        created () {
            this.$nuxt.$on('login-previous-step', () => {
                this.setStep('e-mail')
            })
        },
    }
</script>