<template>
    <div class="search-accounts-container h-100">
        <div class="search-accounts p-3 text-center d-flex flex-column">
            <div class="card-body p-0 scrollable-content">

                <strong class="text-brand title">
                    {{ $t('Come si chiama la tua struttura?')}}
                </strong>
                <div class="description mt-3 mb-4">
                    {{ $t('Inserendo il nome della tua struttura la renderai più facilmente individuabile da tutti.')}}
                </div>

                <div class="account-search-bar d-flex background-white position-sticky w-100 pr-3">
                    <i class="cf-search text-grey font-size-xxl"></i>
                    <input class="w-100 ellipsis font-size-xl" :placeholder="$t('Nome del centro estetico')" v-model="filters.text" v-if="account.type === 'beauty-centre'">
                    <input class="w-100 ellipsis font-size-xl" :placeholder="$t('Nome della scuola di estetica')" v-model="filters.text" v-if="account.type === 'school'">
                    <input class="w-100 ellipsis font-size-xl" :placeholder="$t('Nome dell\'azienda')" v-model="filters.text" v-if="account.type === 'company'">
                    <i class="fa fa-times-circle align-self-center absolute text-grey p-3" style="right: 0" v-if="filters.text" @click.prevent="filters.text = ''"></i>
                </div>
                <div class="accounts background-white" v-if="filters.text.length >= 2">
                    <div class="d-flex align-items-center p-2" @mousedown="event => event.preventDefault()" @click.prevent="nextStep('')">
                        <account-image class="p-2" size="50"></account-image>
                        <div class="ml-2 text-left">
                            <div>
                                <span class="w-100 ellipsis font-size-xl" v-if="account.type === 'beauty-centre'">{{ $t('Crea nuovo centro estetico') }}</span>
                                <span class="w-100 ellipsis font-size-xl" v-if="account.type === 'school'">{{ $t('Crea nuova scuola di estetica') }}</span>
                                <span class="w-100 ellipsis font-size-xl" v-if="account.type === 'company'">{{ $t('Crea nuova azienda cosmetica') }}</span>
                            </div>
                            <span class="text-brand">« <u class="font-weight-bold">{{ filters.text }}</u> »</span>
                        </div>
                    </div>
                    <hr>
                    <div v-if="!loading">
                        <div class="d-flex flex-column" v-for="item in accounts.accounts" @mousedown="event => event.preventDefault()" @click.prevent="nextStep(item)">
                            <div class="d-flex align-items-center p-2">
                                <account-image class="p-2" :account="item" size="50"></account-image>
                                <div class="ml-2 text-left ellipsis">
                                    <strong class="no-wrap"> {{ item.name }}</strong>
                                    <div class="text-grey"> {{ item.address }}</div>
                                </div>
                            </div>
                            <hr v-if="lastAccount && (item.id !== lastAccount.id)">
                        </div>
                    </div>
                </div>
                <div v-if="loading">
                    <i class="fa fa-spin">
                        <img alt="loading" src="~/assets/images/icons/loading.svg" width="36px">
                    </i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import _ from 'lodash'
    const bodyScrollLock = require('body-scroll-lock')

    export default {

        data() {
            return {
                filters: {
                    text: '',
                    type: this.$store.state.register.data.account.type
                },
                loading: false,
                focused: true
            }
        },

        watch: {
            'account.type': function (val) {
                this.filters.type = val;
            },
            'filters.text': _.debounce(function (val) {
                if (val.length >= 2) {
                    this.loading = true;
                    this.fetchAccounts({filters: this.filters}).then(() => {
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                    })
                }
            }, 500)
        },

        computed: {
            ...mapState({
                'account': ({ register }) => register.data.account,
                'accounts': 'accounts',
            }),
            lastAccount() {
                return _.last(this.accounts.accounts);
            }
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch',
                'fetchAccounts': 'accounts/fetchAll',
                'loadMoreAccounts': 'accounts/fetchMore'
            }),
            ...mapMutations({
                'setStep': 'register/SET_STEP',
                'setAccount': 'register/SET_ACCOUNT',
                'setAccountName': 'register/SET_ACCOUNT_NAME',
            }),
            previousStep() {

                // this.backCallBack();
                // this.setWorkExperienceStep('work-experience')
            },
            nextStep(account) {

                if (!account) {

                    this.setAccountName(this.filters.text)
                } else {
                    this.setAccount(account);
                    // this.setStep('account-address')
                }
                this.setStep('name')
            }
        },

        destroyed() {
            let page = document.querySelector('body .page.responsive .content .scrollable-content')

            if (page) {
                bodyScrollLock.enableBodyScroll(page)
            }
        },

        mounted () {
            let page = document.querySelector('body .page.responsive .content .scrollable-content')

            if (page) {
                bodyScrollLock.disableBodyScroll(page)
            }
        },

        created () {
            this.$nuxt.$on('login-previous-step', () => {
                this.setStep('account-vat-id')
            })
        }
    }
</script>

<style lang="scss" scoped>

    @import "assets/sass/typography";

    .search-accounts-container {

        &:not(:focus-within) {
            .accounts {
                display: none;
            }

            input {
                padding: 4px 12px;
                @include font-size-lg;
            }
        }

        &:focus-within {

            padding-top: 0!important;
            padding-bottom: 45px!important;

            .accounts {
                display: block;
            }

            .search-accounts {
                padding: 0!important;
            }

            .title {
                display: none;
            }

            .description {
                display: none;
            }

            .cf-search {
                display: block;
                padding: 0.9em;
            }

            .account-search-bar {
                z-index: 1;
                top: 0;
                border-bottom: 1px solid #DBDBDB;
            }
        }

        .account-search-bar {

            input {
                &::placeholder {
                    color: #DBDBDB;
                }
            }
        }

        .cf-search {
            display: none;
        }
    }

    .account-card {
        box-shadow: 0 3px 7px rgba(0,0,0,0.16), 0 3px 7px rgba(0,0,0,0.1);
    }

    hr {
        margin: 0!important;
    }
</style>