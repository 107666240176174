<template>
    <div class="d-flex flex-column px-3">
        <strong class="text-brand title">
            {{ $t('Iscriviti a Confestetica')}}
        </strong>
        <div class="description mt-3">
            {{ $t('Ti aiuteremo a creare il tuo account professionale in pochi semplici passaggi.') }}
        </div>
        <a href="#" class="btn btn-brand mt-3 w-100" @click.prevent="setStep('type')">
            {{ $t('Inizia') }}
        </a>
    </div>
</template>

<script>

    import { mapActions } from 'vuex'

    export default {
        methods: {
            ...mapActions({
                'setStep': 'register/setStep'
            })
        },
        created () {
            this.$nuxt.$on('login-previous-step', () => {
                this.setStep('login')
            })
        },
    }
</script>