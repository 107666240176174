<template>
    <div class="d-flex flex-column px-3">
        <div class="mt-5">
            <strong class="text-primary title">
                {{ $t('Data di nascita')}}
            </strong>
        </div>
        <div class="d-flex mt-3">
            <input type="date" class="form-control form-control has-label" name="first_name" :data-vv-as="$t('Data di nascita')" :placeholder="$t('Data di nascita')" autocomplete="off" v-model="birth_date" />
            <div class="form-error text-danger" v-if="errors.has('birth_date')">{{ errors.first('birth_date') }}</div>
        </div>
        <div class="mt-3">
            {{ $t('Puoi scegliere chi può vedere questa informazione dal tuo profilo.')}}
        </div>
<!--    <a href="#" class="text-brand no-decoration">{{ $t('Perchè devo fornire la mia data di nascita?') }}</a> NO LINK TO POINT TO!! -->
        <button class="btn btn-brand mt-3 w-100" @click.prevent="setStep('gender')" :disabled="!birth_date">
            {{ $t('Avanti') }}
        </button>
    </div>
</template>

<script>

    import { mapActions } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        computed: {
            ...mapFields('register', [
                'data.birth_date',
                'processing'
            ]),
        },

        methods: {
            ...mapActions({
                'setStep': 'register/setStep'
            }),

        },
        created () {
            this.$nuxt.$on('login-previous-step', () => {
                this.setStep('name')
            })
        },
    }
</script>
