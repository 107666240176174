<template>
    <div class="d-flex flex-column px-3">
        <div class="mt-5">
            <strong class="text-primary title">
                {{ $t('Uomo o donna?')}}
            </strong>
        </div>
        <div class="d-flex mt-3 justify-content-center">
            <div class="gender-box d-flex justify-content-center align-items-center title text-primary flex-column mr-2" @click.prevent="setGender('female')" :class="{active: gender === 'female'}">
                <div class="mb-3">
                    <img alt="user-f-grey" src="~/assets/images/icons/user-f-grey.svg" height="50px" v-if="gender === 'female'">
                    <img alt="user-f" src="~/assets/images/icons/user-f.svg" height="50px" v-else>
                </div>
                {{ $t('Donna') }}
            </div>
            <div class="gender-box d-flex justify-content-center align-items-center title text-primary flex-column ml-2" @click.prevent="setGender('male')" :class="{active: gender === 'male'}">
                <div class="mb-3">
                    <img alt="user-m-grey" src="~/assets/images/icons/user-m-grey.svg" height="50px" v-if="gender === 'male'">
                    <img alt="user-m" src="~/assets/images/icons/user-m.svg" height="50px" v-else>
                </div>
                {{ $t('Uomo') }}
            </div>
        </div>
        <div class="description mt-3">
            {{ $t('Puoi modificare questo elemento successivamente dalle tue impostazioni.')}}
        </div>
    </div>
</template>

<script>

    import { mapActions } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        computed: {
            ...mapFields('register', [
                'data.gender'
            ]),
        },

        methods: {
            ...mapActions({
                'setStep': 'register/setStep'
            }),
            setGender(gender) {

                this.gender = gender
                this.setStep('e-mail')
            }

        },
        created () {
            this.$nuxt.$on('login-previous-step', () => {
                this.setStep('birth-date')
            })
        },
    }
</script>