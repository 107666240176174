<template>
    <div class="d-flex flex-column px-3">
        <div v-if="user.profile_type === 'not_beautician'" class="d-flex flex-column">
            <strong class="text-primary title">
                {{ $t('Stai creando: ')}}
            </strong>
            <strong class="title mt-3">{{ user.account.name }}</strong>
            <div class="">{{ $t('P. IVA ')}} {{ user.account.vat_id }}</div>
        </div>

        <strong class="text-primary title mt-3">
            {{ $t('Inserisci il tuo nome')}}
        </strong>
        <div class="d-flex mt-3">
            <div class="mr-2">
                <input class="form-control form-control has-label" name="first_name" :data-vv-as="$t('Nome')" v-validate="'required'" :placeholder="$t('Nome')" autocomplete="off" v-model="first_name" />
                <div class="form-error text-danger" v-if="errors.has('first_name')">{{ errors.first('first_name') }}</div>
            </div>
            <div class="ml-2">
                <input class="form-control form-control has-label" name="last_name" :data-vv-as="$t('Cognome')" v-validate="'required'" :placeholder="$t('Cognome')" autocomplete="off" v-model="last_name" />
                <div class="form-error text-danger" v-if="errors.has('last_name')">{{ errors.first('last_name') }}</div>
            </div>
        </div>
        <div class="description mt-3">
            <span v-if="user.profile_type !== 'not_beautician'">{{ $t('Inserendo nome e cognome potrai ottenere un profilo professionale certificato.')}}</span>
            <span v-else class="font-size-sm text-grey d-flex justify-content-between align-items-center">
                <label for="accept_ownership" class="mb-0">{{ $t('Dichiaro di essere titolare di ') }} <strong> {{ user.account.name }} </strong></label>
                <input id="accept_ownership" type="checkbox" v-model="accept_ownership">
            </span>
        </div>
        <button class="btn btn-brand mt-3 w-100" @click.prevent="nextStep()" :disabled="!last_name || !first_name || (!accept_ownership && user.profile_type === 'not_beautician')">
            {{ $t('Conferma') }}
        </button>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        data () {
            return {
                accept_ownership: false
            }
        },

        computed: {
            ...mapState({
                'user': ({ register }) => register.data
            }),
            ...mapFields('register', [
                'data.first_name',
                'data.last_name',
            ]),

        },

        methods: {
            ...mapActions({
                'setStep': 'register/setStep'
            }),
            nextStep() {
                if (this.user.profile_type === 'not_beautician') {
                    this.setStep('e-mail')
                } else {
                    this.setStep('birth-date')
                }
            },
        },
        created () {
            this.$nuxt.$on('login-previous-step', () => {
                if (this.user.profile_type === 'not_beautician') {
                    this.setStep('search-accounts')
                } else {
                    this.setStep('type')
                }
            })
        }
    }
</script>